<template>
  <div class="label">
    <img v-if="item.isRecommend" src="@/assets/img/common/icon_label_recommend.png" alt="추천">
    <img v-if="item.isBest" src="@/assets/img/common/icon_label_best.png" alt="인기">
  </div>
</template>

<script>
export default {
  name: "iconLabel",
  props: ['item'],
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
